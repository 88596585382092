import {
    HISTORY_MAX_LENGTH,
    ARTICLE_HISTORY_KEY,
    ARTICLE_FAVORITES_KEY,
    FAVORITES_MAX_LENGTH
} from '../common/const'
import store from '../common/store'

Array.prototype.remove = function(val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

const common = {

    /**
     * 去重
     * @param {进行操作的数组} array 
     * @returns 
     */
    noRepeat(array) {
        let newArr = []
        let myset = new Set(array)  // 利用了Set结构不能接收重复数据的特点
        for (let val of myset) {
            newArr.push(val)
        }
        return newArr;
    },

    /**
     * 添加历史记录
     * @param {文章Id} articleId 
     */
    addArticleHistory(articleId) {
        let history = this.getArticleHistory()
        
        // 在开头插入记录
        history.unshift(articleId)

        // 去重
        history = this.noRepeat(history)

        // 只保留最大长度的浏览记录个数
        if (history.length >= HISTORY_MAX_LENGTH) {
             history = history.slice(0, HISTORY_MAX_LENGTH)
        }

        // 保存历史记录
        store.set(ARTICLE_HISTORY_KEY, history)
    },

    /**
     * 删除浏览记录
     * @param {浏览记录ID} articleId 
     * @returns 
     */
    removeArticleHistory(articleId) {
        let history = this.getArticleHistory()
        
        // 删除记录
        history.remove(articleId)

        // 保存历史记录
        store.set(ARTICLE_HISTORY_KEY, history)
        return true
    },

    /**
     * 获取文章浏览记录
     * @returns 浏览记录ID数组
     */
    getArticleHistory() {
        return store.get(ARTICLE_HISTORY_KEY, [])
    },

    /**
     * 获取文章浏览记录长度
     * @returns 浏览记录数组长度
     */
    getArticleHistoryCount() {
        return this.getArticleHistory().length
    },

    /**
     * 添加收藏记录
     * @param {文章Id} articleId 
     */
    addArticleFavorite(articleId) {
        let records = this.getArticleFavorites()
        articleId = articleId - 0
        
        // 在开头插入记录
        records.unshift(articleId)

        // 去重
        records = this.noRepeat(records)

        // 只保留最大长度的收藏记录个数
        if (records.length >= FAVORITES_MAX_LENGTH) {
             records = records.slice(0, FAVORITES_MAX_LENGTH)
        }

        // 保存收藏记录
        store.set(ARTICLE_FAVORITES_KEY, records)
    },

    /**
     * 删除收藏记录
     * @param {收藏记录ID} articleId 
     * @returns 
     */
    removeArticleFavorite(articleId) {
        let records = this.getArticleFavorites()
        articleId = articleId - 0
        
        // 删除记录
        records.remove(articleId)

        // 保存收藏记录
        store.set(ARTICLE_FAVORITES_KEY, records)
        return true
    },

    /**
     * 获取文章收藏记录
     * @returns 收藏记录ID数组
     */
    getArticleFavorites() {
        return store.get(ARTICLE_FAVORITES_KEY, [])
    },

    /**
     * 获取文章收藏记录长度
     * @returns 收藏记录数组长度
     */
    getArticleFavoriteCount() {
        return this.getArticleFavorites().length
    },

    /**
     * 是否已收藏
     * @param {文章Id} articleId 
     * @returns 
     */
    hasCollected(articleId) {
        let records = this.getArticleFavorites()
        articleId = articleId - 0
        
        return records.indexOf(articleId) >= 0
    },

    /**
     * 获取分类文章首次加载的列表内容
     * @param {分类Id} categoryId 
     * @returns 
     */
    getRefreshArticles(categoryId) {
        return store.get(`__ARTICLES_${categoryId}__`, [])
    },

    /**
     * 保存分类文章首次加载的列表内容
     * @param {分类Id} categoryId 
     * @param {文章列表内容} articles 
     */
    setRefreshArticles(categoryId, articles) {
        store.set(`__ARTICLES_${categoryId}__`, articles)
    },

    /**
     * 获取分类首次加载的列表内容
     * @param {父级分类Id} parentId 
     * @returns 
     */
    getRefreshAlbums(parentId) {
        return store.get(`__ALBUMS_${parentId}__`, [])
    },

    /**
     * 保存分类首次加载的列表内容
     * @param {父级分类Id} parentId 
     * @param {文章列表内容} articles 
     */
    saveRefreshAlbums(parentId, categories) {
        store.set(`__ALBUMS_${parentId}__`, categories)
    }

}

export default common