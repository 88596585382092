const store = {
  get(key, defaultValue) {
    let result = defaultValue
    try {
      result = JSON.parse(localStorage.getItem(key)) ?? defaultValue
    } catch(err) {
      console.log("store get JSON.parse 错误信息：" + err.message);
    }
    return result
    // return JSON.parse(localStorage.getItem(key)??'') || defaultValue
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export default store