import request from '../utils/request'

/**
 * 获取最新文章列表
 */
export const getNewestArticles = () => {
    let data = ``
    return request.get('/article/list/newest', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => {
        let code = res.data.code
        return (code === 200) ? res.data.data : []
    })
}

/**
 * 获取文章列表
 */
export const getArticles = (categoryId, pageNum = 1, keyword = '', type = 0) => {
    let data = `title=${keyword}&type=${type}&pageSize=50`
    return request.get(`/article/list/${categoryId}/${pageNum}` + '?' + data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => {
        let code = res.data.code
        return (code === 200) ? res.data.data : []
    })
}

/**
 * 获取文章详情
 */
export const getArticleDetail = (articleId) => {
    let data = ``
    return request.get(`/article/detail/${articleId}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data.data)
}

/**
 * 通过多个文章Id获取文章列表
 * @param {文章Id列表} articleIds 
 * @returns 
 */
export const getArticlesByIds = (articleIds) => {
    let data = ``
    return request.get(`/article/list/ids/${articleIds}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => {
        let code = res.data.code
        return (code === 200) ? res.data.data : []
    })
}
