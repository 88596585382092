<template>
    <div class="article_pull_list">

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="van-clearfix">
            
            <template v-if="article_list && article_list.length > 0">
            <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" class="van-clearfix">

                <van-row :gutter="20" class="van-clearfix">
                    <van-col span="12" v-for="item in article_list" :key="item.articleId" :title="decryptTitle(item)" class="van-clearfix">
                    
                    <router-link :to="`/detail/${item.articleId}`">
                        <div class="article-item van-clearfix">
                            <van-image :src="item.cover" width="100%" height="15rem" fit="cover">
                                <template v-slot:loading>
                                    <van-loading type="spinner" size="20" />
                                </template>
                            </van-image>
                            
                            <p class="title">{{decryptTitle(item)}}</p>
                        </div>
                    </router-link>
                    
                    </van-col>
                </van-row>

            </van-list>
            </template>

            <template v-else>
            <van-empty image="search" description="无搜索结果" />
            </template>

        </van-pull-refresh>

    </div>
</template>

<script>
import { getArticles } from '../api/article'
import common from '../utils/common'
import crypto from '../utils/crypto_utils'

export default {
    name: 'ArticlePullList',
    components: {
    },
    props: {
        categoryId: {
            type: String,
            default: '0'
        },
        keyword: {
            type: String,
            default: ''
        },
        type: {
            type: Number,
            default: 0
        }
    },
    setup() {
    },
    mounted() {
    },
    data() {
        return {
            loading: false,
            finished: false,
            refreshing: false,

            page: 1,
            article_list: []
        }
    },
    created() {
        // 首次加载已保存的数据
        this.article_list = common.getRefreshArticles(this.categoryId)
        this.init()
    },
    methods: {
        init() {
            this.onRefresh()
        },
        /**
         * 上拉加载更多
         */
        async onLoad() {
            if (this.refreshing) {
                this.page = 1;
                this.article_list = [];
                this.refreshing = false;
            } else {
                this.page++;
            }
            
            let list = await getArticles(this.categoryId, this.page, this.keyword, this.type)

            // 保存下拉刷新得到的数据
            if (this.page == 1 && (list && list.length > 0)) {
                common.setRefreshArticles(this.categoryId, list)
            }

            if (list && list.length > 0) {
                list.forEach(item => { this.article_list.push(item) });
                this.loading = false;       // 加载完成
            } else {
                this.finished = true;        // 没有更多
            }
        },
        /**
         * 下载刷新
         */
        onRefresh() {
            // 重新加载数据
            this.refreshing = true;
            // 清空列表数据
            this.finished = false;

            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        /**
         * 解密标题内容
         * @param {文章数据} item 
         */
        decryptTitle(item) {
            // 加密过的标题
            return crypto.decryptTitle(item.title)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .van-row
        text-align: center

        div.article-item
          position: relative
          margin-bottom: 1.2em

        .title
            position: absolute
            left: 0
            bottom: 0
            width: 100%
            margin: 0
            color: white
            font-size: 0.8em
            background-color: #0000004f
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            line-height: 2em
            padding-left: 0.5em;
</style> 