<template>
    <van-nav-bar title="搜索页" left-arrow>
        <template #right>
            <van-icon name="search" size="18" @click="onClickSearch"/>
        </template>
    </van-nav-bar>

    <form action="/">
        <van-search
            v-model="keyword"
            show-action
            shape="round"
            background="#4fc08d"
            placeholder="请输入搜索关键词"
            @search="onClickSearch"
            @cancel="onCancel"
            >
        </van-search>
    </form>

    <div class="search_page">

        <section v-show="showResult" class="result">
            <van-radio-group direction="horizontal" v-model="selectedType">
                <template v-for="item in typeList" :key="item.id">
                    <van-radio :name="item.id">{{ item.title }}</van-radio>
                </template>
            </van-radio-group>

            <div class="van-clearfix"></div>

            <article-pull-list ref="articleList" :keyword="keyword" :type="selectedType"></article-pull-list>
        </section>

        <!-- 历史记录 -->
        <section v-show="!showResult" class="history">
            <van-button type="danger" icon="delete" block plain @click="onClickClearHistory">清空所有搜索记录</van-button>

            <template v-for="item in history" :key="item">
                <van-tag plain mark size="large" type="primary" @click="onClick(item)">
                    {{ item }}
                </van-tag>
            </template>
        </section>

    </div>
</template>

<script>
import store from '../../common/store'
import ArticlePullList from '../../components/ArticlePullList'
import { Dialog } from 'vant';
import { SEARCH_HISTORY_KEY } from '../../common/const'

export default {
    name: 'SearchPage',
    components: {
        ArticlePullList
    },
    setup() {
        Array.prototype.indexOf = function(val) { for (var i = 0; i < this.length; i++) { if (this[i] == val) return i; } return -1; };

        Array.prototype.remove = function(val) { var index = this.indexOf(val); if (index > -1) { this.splice(index, 1);  } };
    },
    mounted() {
        // 获取搜索历史
        this.getHistory()
    },
    data() {
        return {
            showResult: false,
            selectedType: 0,
            typeList: [
                {"id": 0, "title": "全部"},
                {"id": 1, "title": "图片"},
                {"id": 2, "title": "视频"},
            ],
            history: [],

            keyword:'',
            article_list: [],
            page: 1
        }
    },
    methods: {
        /**
         * 获取搜索历史
         */
        getHistory() {
            let list = store.get(SEARCH_HISTORY_KEY, [])
            if (list.length > 0)
                this.history = JSON.parse(list)
        },

        /**
         * 添加搜索记录
         * @param {string} keyword 关键词
         */
        addHistory(keyword) {
            this.history.remove(keyword)
            this.history.unshift(this.keyword)

            store.set(SEARCH_HISTORY_KEY, JSON.stringify(this.history))
        },

        /**
         * 删除对应的搜索记录
         * @param {string} keyword 关键词 
         */
        removeHistory(keyword) {
            this.history.remove(keyword)
            store.set(SEARCH_HISTORY_KEY, JSON.stringify(this.history))
        },

        /**
         * 清空所有搜索记录
         */
        clearHistory() {
            store.set(SEARCH_HISTORY_KEY, '')
            this.history = []
        },

        /**
         * 点击对应的搜索记录
         * @param {string} val 
         */
        onClick(val) {
            this.keyword = val
            this.onClickSearch()
        },

        /**
         * 取消搜索
         */
        onCancel() {
            this.showResult = false
        },

        /**
         * 执行搜索功能
         */
        async onClickSearch() {
            this.addHistory(this.keyword)
            this.showResult = true

            // 执行搜索操作
            // 调用子组件的搜索功能
            this.$refs.articleList.onRefresh()
        },

        onClickClearHistory() {
            Dialog.confirm({
                message:
                '是否清空所有搜索记录？',
            })
            .then(() => {
                // on confirm
                this.clearHistory()
            })
            .catch(() => {
                // on cancel
            });
        }
    }
}
</script>

<style lang="stylus" scoped>
    section.result

        .van-radio-group
            margin: 16px

    section.history
        padding: 16px

        .van-button
            margin-bottom: 20px

        .van-tag
            margin: 3px 3px
</style>